import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'; // Ensure App is imported here
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />  {/* Render the App component */}
  </React.StrictMode>,
  document.getElementById('root')  // Ensure this matches the div id in index.html
);

// Optional: For performance monitoring
reportWebVitals();

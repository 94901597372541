// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';  // Import the Login CSS for the styles

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Replace with your actual backend URL
      const response = await axios.post('http://localhost:8080/api/auth/login', {
        email,
        password,
      });

      // Handle successful login
      console.log('Login successful:', response.data);

      // Store token in localStorage
      localStorage.setItem('token', response.data.token);

    } catch (err) {
      // Handle login error
      setError('Invalid email or password');
      console.error('Login error:', err.response ? err.response.data : err.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Logup</h2>
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
